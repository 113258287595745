//Importing our Fonts File
@import './shared/fonts';
//Importing our Nav Bar File
@import './shared/navbar.scss';
//Importing our Top Info File
@import './shared/topinfo';
//Importing our Index File
@import './pages/index';
//Importing our Main page File
@import './pages/main-page';
//Importing our Services page File
@import './pages/services-page';
//Importing our Prices page File
@import './pages/prices-page';
//Importing our Contacts page File
@import './pages/contacts-page';
//Importing our Footer page File
@import './pages/footer-page';

// $color: #32867d;
$color: #017768;
$color-light: #019582;

html,
body {
	background-color: #fff;
	font-family: 'Montserrat', 'Helvetica';
	margin: 0px;
	width: 100%;
	line-height: 1.9;
	font-size: 16px;
	overflow-x: hidden !important;
	min-height: -webkit-fill-available;
}
.pregnancy {
	color: $color;
}
.no-hover {
	pointer-events: none;
}

.clickable {
	cursor: pointer;
	&:hover {
		text-decoration: none;
	}
}

.page-links {
	font-weight: 700;
	color: #04cbb0;
	&:hover,
	:active,
	:focus {
		color: $color-light;
		text-decoration: none;
	}
}
.inline {
	display: inline;
}

.absolute {
	position: absolute;
}

.base-page {
	color: #444444;
}

.full-screen_page {
	height: 100vh;
}

.topinfo-page {
	min-height: 65px;
}

.main-page {
	height: calc(80vh - 65px);
}

.info-page {
	min-height: 55vh;
}

.contacts-page {
	min-height: 20vh;
}

.prices-page,
.pregnancy-page,
.about-page {
	min-height: calc(75vh - 107px);
}

.page_text {
	padding-top: 100px;
}

.page_subtext {
	margin-top: -10px;
	font-size: 1em;
	color: $color;
	font-weight: 700;
	& a {
		color: $color;
		text-decoration: none;
	}
}

@media (max-width: 768px) {
	.page_text {
		padding-top: 25px;
		// font-size: 0.9em;
	}
}
