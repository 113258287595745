//Partial File to be imported in main.scss
//FOOTER PAGE

$color: #017768;
$color-light: #019582;
$color-bg: #c4d5d29e;
.footer-page {
	height: 30px;
	text-align: center;
	color: $color;
	background-color: $color-bg !important;
	a:hover {
		color: $color;
		text-decoration: none;
	}
}

@media (max-width: 576px) {
	.footer-page {
		font-size: 3vw;
	}
}
