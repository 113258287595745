//Partial File to be imported in main.scss
@font-face {
	font-family: 'Montserrat';
	src: url('/fonts/Montserrat-Regular.ttf');
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: 'Montserrat';
	src: url('/fonts/Montserrat-Bold.ttf');
	font-weight: bold;
	font-style: normal;
}
@font-face {
	font-family: 'Montserrat';
	src: url('/fonts/Montserrat-Italic.ttf');
	font-weight: normal;
	font-style: italic;
}
