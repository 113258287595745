//Partial File to be imported in main.scss
//INDEX PAGE
$color: #017768;
$color-light: #019582;
$color-bg: #c4d5d29e;

.cover {
	overflow: hidden;
}

.red {
	color: red;
}

.loading {
	z-index: 9999;
	position: fixed;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.4);
	& svg {
		position: absolute;
		height: 100px;
		width: 100%;
		top: 50%;
	}
}

.shadowed-img {
	box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.034), 0 6.7px 5.3px rgba(0, 0, 0, 0.048),
		0 12.5px 10px rgba(0, 0, 0, 0.06), 0 12.3px 9.9px rgba(0, 0, 0, 0.072), 0 21.8px 15.4px rgba(0, 0, 0, 0.086),
		0 50px 40px rgba(0, 0, 0, 0.12);

	border-radius: 5px;
}

#open_booking-iframe-image {
	float: none;
	position: fixed;
	right: -2px;
	bottom: 13vh;
	display: block;
	width: 30px;
	z-index: 20000;
	cursor: pointer;
	cursor: hand;
}

.booking_button {
	padding: 0.5em 2em;
	text-align: center;
	text-decoration: none;
	color: #fff;
	background-color: $color-light;
	border: 1px solid $color-light;
	font-size: 24px;
	display: inline-block;
	border-radius: 0.3em;
	transition: all 0.2s ease-in-out;
	position: relative;
	overflow: hidden;
	box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.034), 0 6.7px 5.3px rgba(0, 0, 0, 0.048),
		0 12.5px 10px rgba(0, 0, 0, 0.06), 0 12.3px 9.9px rgba(0, 0, 0, 0.072), 0 21.8px 15.4px rgba(0, 0, 0, 0.086),
		0 50px 40px rgba(0, 0, 0, 0.12);
	&:before {
		content: "";
		background-color: rgba(255, 255, 255, 0.5);
		height: 100%;
		width: 3em;
		color: #fff;
		display: block;
		position: absolute;
		top: 0;
		left: -4.5em;
		transform: skewX(-45deg) translateX(0);
		transition: none;
	}
	&:hover {
		text-decoration: none;
		background-color: $color-light;
		color: #fff !important;
		border-bottom: 1px solid darken($color, 10%);
		&:before {
			transform: skewX(-45deg) translateX(13.5em);
			transition: all 0.5s ease-in-out;
		}
	}
}

@keyframes sheen {
	0% {
		transform: skewY(-45deg) translateX(0);
	}
	100% {
		transform: skewY(-45deg) translateX(12.5em);
	}
}
