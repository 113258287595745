//Partial File to be imported in main.scss
//TOPINFO PAGE

.topinfo_logo-header {
	&-company,
	&-location,
	&-time,
	&-phone {
		p {
			margin-bottom: -0.2rem;
			font-size: 0.9em;
		}
		a {
			text-decoration: none;
			color: #444444;
		}
	}
	&-location,
	&-time,
	&-phone {
		.title {
			font-size: 0.9em;
			font-weight: 600;
			color: $color;
			line-height: 1;
		}
		a {
			display: inline-block;
		}
	}
	&-company .title {
		margin-top: -5px;
		font-size: 2em;
	}
	&-company .colored {
		color: $color;
	}
	&-company {
		margin-bottom: 10px;
		line-height: 1;
	}
}

.fai_map-marker,
.fai_clock,
.fai_phone {
	position: absolute;
	top: 20%;
	left: -2%;
}

.company_logo {
	position: absolute;
	top: -15px;
	left: 30%;
}

@media (max-width: 350px) {
	.topinfo_logo-header-company {
		text-align: center;
		& p {
			font-size: 0.7em;
		}
	}
}

@media (max-width: 992px) {
	.topinfo_logo-header-company {
		text-align: center;
	}

	.company_logo {
		bottom: 15%;
	}
}

@media (max-width: 1200px) {
	.topinfo_logo-header-company {
		p {
			font-size: 0.8em;
		}
	}
}
