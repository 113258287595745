//Partial File to be imported in main.scss
//MAIN PAGE
$color: #017768;

.main-page_text {
	font-weight: 500;
	margin-left: 0.6%;
	width: 35vw;
	top: 140px;
	color: #f4f8fa;
	backdrop-filter: blur(5px);
	& h3 {
		font-size: 1.6em;
		padding-bottom: 20px;
	}
	& p {
		font-size: 1.1em;
	}
}

.main-page_covid-text {
	top: 150px;
	right: 0%;
	// background: #2e6c8773;
	// backdrop-filter: blur(5px);
	& a {
		color: #f4f8fa !important;
	}
}

.main-page_images {
	height: calc(85vh - 65px);
	object-fit: cover;
}

.carousel-control-next,
.carousel-control-prev {
	top: 60px;
	width: 50px;
}
.carousel-control-prev-icon {
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23017768' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e");
}
.carousel-control-next-icon {
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23017768' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e");
}
.carousel-indicators li {
	opacity: 0.3;
	background-color: #017768;
}
.carousel-caption {
	h3 {
		color: #444444;
		font-size: 50px;
		font-weight: bold;
	}
	p {
		font-weight: bold;
		color: #019582;
	}
	margin-bottom: 10%;
}
.image-gallery-thumbnail.active,
.image-gallery-thumbnail:hover,
.image-gallery-thumbnail:focus {
	border: 4px solid $color !important;
}
.image-gallery-icon:hover {
	color: $color !important;
}

.image-gallery-slide-wrapper {
	box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.034), 0 6.7px 5.3px rgba(0, 0, 0, 0.048), 0 12.5px 10px rgba(0, 0, 0, 0.06),
		0 12.3px 9.9px rgba(0, 0, 0, 0.072), 0 21.8px 15.4px rgba(0, 0, 0, 0.086), 0 50px 40px rgba(0, 0, 0, 0.12) !important;
}

.image-gallery-image {
	border-radius: 5px 5px 3px 3px;
}

.info-page_gallery {
	margin-top: 140px;
}

@media (max-width: 768px) {
	.main-page_text {
		width: 105%;
		padding-left: 7%;
		padding-right: 10%;
		margin-top: 0%;
		font-size: 0.9em;
		& h2 {
			font-size: 1.5em;
		}
	}

	.info-page_gallery {
		margin-top: 0px;
	}
}
