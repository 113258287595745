//Partial File to be imported in main.scss
//PRICES PAGE
$color: #017768;

.prices-images {
  height: 100%;
  border-radius: 0px 0px 500px 0px;
  max-height: 250px;
}

.card-price {
  text-align: center;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 28px;
  color: $color;
}

.prices-card {
  height: 100%;
  min-height: 550px;
}

@media (max-width: 992px) {
  .prices-images {
    max-height: 400px;
  }
}
