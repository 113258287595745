//Partial File to be imported in main.scss
//CONSULTATION PAGE

.services-page_img-container {
  padding: 45px 10px 40px 10px;
}

.services-page_text-container {
  padding: 70px 10px 50px 10px;
}

.services-page_hr {
  width: 250px;
  float: left;
}

.services-images {
  max-height: 160px;
}
.services-card {
  height: 100%;
  min-height: 630px;
}
.shadow_box {
  background: #e0eef1;
  border-radius: 5px;
  padding: 20px;
}

@media (max-width: 768px) {
  .services-page_img-container,
  .services-page_text-container {
    padding: 30px 10px 30px 10px;
  }
}

@media (max-width: 992px) {
  .services-images {
    max-height: 400px;
  }
}
