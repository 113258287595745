//Partial File to be imported in main.scss
//CONTACTS PAGE
$color: #017768;
$color-bg: #444444;
$color-light: #019582;


label {
	margin-bottom: 0;
}

.contacts-page {
	background-color: $color-bg;
}

.contacts-page_text {
	color: #fff
}

.contact-form_button {
	padding: 0.3em 1em;
	text-align: center;
	text-decoration: none;
	color: #fff;
	background-color: $color-light;
	border: 1px solid $color-light;
	font-size: 16px;
	display: inline-block;
	border-radius: 0.3em;
	transition: all 0.2s ease-in-out;
	position: relative;
	overflow: hidden;
	&:before {
		content: "";
		background-color: rgba(255, 255, 255, 0.5);
		height: 100%;
		width: 3em;
		color: #fff;
		display: block;
		position: absolute;
		top: 0;
		left: -4.5em;
		transform: skewX(-45deg) translateX(0);
		transition: none;
	}
	&:hover {
		text-decoration: none;
		background-color: $color-light;
		color: #fff !important;
		border-bottom: 1px solid darken($color, 10%);
		&:before {
			transform: skewX(-45deg) translateX(13.5em);
			transition: all 0.5s ease-in-out;
		}
	}
}
